import moment from 'moment'

// can be removed after removing fullcalendar
window.moment = moment

export { moment }

declare global {
  interface Window {
    moment: typeof moment
  }
}
